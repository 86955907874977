<template>
  <div class="cdr-block-multiselect-jadwal">
    <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>