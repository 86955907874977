<template>
    <div class="doctor-accordion">
        <button class="doctor-accordion-button" block v-b-toggle="accordionId">
            <div class="d-flex" :style="`justify-content: space-between;`">
                <div class="d-flex accordion-head">
                    <p
                        v-for="(hari, index) in daysData"
                        :key="index"
                        :style="
                            !hari.isActive
                                ? `text-decoration-line: line-through;color: #C1C1C1;`
                                : ''
                        "
                    >
                        {{ hari.day }}
                    </p>
                </div>
                <div
                    v-if="tableData.length > 0"
                    style="padding: 10px 0 0 0;"
                    class="d-flex show-table"
                >
                    <!-- <p class="jadwal-chev">
                        Tampilkan Jadwal
                    </p> -->
                    <div>
                        <NextChevIcon
                            :style="
                                isJustShown
                                    ? 'transform: rotate(270deg);'
                                    : 'transform: rotate(90deg);'
                            "
                        />
                    </div>
                </div>
            </div>
        </button>
        <b-collapse
            v-if="tableData.length > 0"
            :id="accordionId"
            accordion="my-accordion"
            role="tabpanel"
        >
            <div style="margin-top:13px;" class="accordion-table-container">
                <JadwalTable :tableData="tableData" :tableHeader="tableHeader">
                </JadwalTable>
            </div>
        </b-collapse>
    </div>
</template>

<script>
import NextChevIcon from "../../_icons/NextChevIcon.vue";
// import PublishChips from "../components/PublishChips.vue";
import JadwalTable from "./JadwalTable.vue";
export default {
    name: "TableAccordion",
    components: {
        NextChevIcon,
        JadwalTable
        // PublishChips
    },
    data() {
        return {
            tableHeader: [
                `Hari`,
                `Jam Mulai`,
                `Jam Selesai`,
                `Durasi`,
                `Tanggal Mulai`,
                `Tanggal Selesai`,
                `Jadwal`,
                `Jam`,
                `Slot`
            ]
        };
    },
    props: {
        daysData: {
            type: Array
        },
        accordionId: {
            type: String
        },
        isJustShown: {
            type: Boolean
        },
        tableData: {
            type: Array
        }
    }
};
</script>

<style></style>
