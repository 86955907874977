import Photo from "../../../model/photo-model";
const md5 = require("md5");
export default class DoctorDataClass {
    // constructor(data, icons) {
    //     this.iconData = icons;
    //     this.doctorList = data;
    // }
    static changeColorBorderSlot2(idJadwal) {
        let hexMd5 = this.getMd5(idJadwal);
        return hexMd5.slice(0, 6);
    }
    static getMd5(str) {
        return md5(str);
    }
    static iconConditionMatch(condition, type, icons) {
        let iconObject = {};
        icons.find(icon => {
            if (icon.type == type && icon.condition == condition) {
                iconObject = {
                    icon: icon.icon,
                    type: "component",
                    title: type
                };
            }
        });

        return iconObject;
    }
    static doctorCardChipIconObject(data, icons) {
        let conditions = this.iconsConditionObject(data);
        let konsultasiIcons = [];
        let bookingIcons = [];

        const types = ["facetoface", "telekonsul", "caredoctor", "callcenter"];

        konsultasiIcons.push(
            this.iconConditionMatch(conditions.isFaceToFace, types[0], icons)
        );
        konsultasiIcons.push(
            this.iconConditionMatch(conditions.isTelekonsul, types[1], icons)
        );
        bookingIcons.push(
            this.iconConditionMatch(conditions.isCareDoctor, types[2], icons)
        );
        bookingIcons.push(
            this.iconConditionMatch(conditions.isCallCenter, types[3], icons)
        );

        return {
            konsultasiIcons,
            bookingIcons
        };
    }
    static iconsConditionObject(data) {
        let isTelekonsul = data.tarif ? true : false;
        let isFaceToFace = true;
        let isCareDoctor = data.isBookAllow;
        let isCallCenter = true;

        return {
            isTelekonsul,
            isFaceToFace,
            isCareDoctor,
            isCallCenter
        };
    }
    static klinikMapper(data) {
        let klinik = [];
        if (data) {
            if (Array.isArray(data)) {
                data.forEach(item => {
                    if (item.klinik) {
                        klinik.push(item.klinik.namaIndo);
                    }
                });
            }
        }
        return klinik;
    }
    static spesialisasiMapper(data) {
        let spesialisasiList = [];
        if (data) {
            if (Array.isArray(data)) {
                data.forEach(item => {
                    if (item.spesialisasi) {
                        spesialisasiList.push({
                            nama: item.spesialisasi.namaIndo,
                            isActive: item.isPublish
                        });
                    }
                });
            }
        }
        return spesialisasiList;
    }
    static subSpesialisasiMapper(data) {
        let subSpesialisasiList = [];
        if (data) {
            if (Array.isArray(data)) {
                data.forEach(item => {
                    if (item.subSpesialisasi) {
                        subSpesialisasiList.push({
                            nama: item.subSpesialisasi.namaIndo,
                            isActive: item.isPublish
                        });
                    }
                });
            }
        }
        return subSpesialisasiList;
    }
    static numToHari(num) {
        let days = {
            0: `Senin`,
            1: `Selasa`,
            2: `Rabu`,
            3: `Kamis`,
            4: `Jumat`,
            5: `Sabtu`,
            6: `Minggu`
        };
        return days[num];
    }
    static numToDays(num) {
        let days = {
            0: `MONDAY`,
            1: `TUESDAY`,
            2: `WEDNESDAY`,
            3: `THURSDAY`,
            4: `FRIDAY`,
            5: `SATURDAY`,
            6: `SUNDAY`
        };
        return days[num];
    }
    static daysToNum(day) {
        let days = {
            senin: 0,
            selasa: 1,
            rabu: 2,
            kamis: 3,
            jumat: 4,
            sabtu: 5,
            minggu: 6,
            monday: 0,
            tuesday: 1,
            wednesday: 2,
            thursday: 3,
            friday: 4,
            saturday: 5,
            sunday: 6
        };
        let hariNum = day;
        if (typeof day !== "number") {
            hariNum = days[day.toLowerCase()];
        }
        return hariNum;
    }
    // static bahasaToShorhand(bahasa) {
    //     let enums = {
    //         "Bahasa Indonesia": "ID",
    //         English: `EN`,
    //         Japanese: `JP`
    //     };
    //     return enums[bahasa];
    // }
    static activeDaysMapper(data) {
        let activeDays = [
            {
                day: "Senin",
                isActive: false
            },
            {
                day: "Selasa",
                isActive: false
            },
            {
                day: "Rabu",
                isActive: false
            },
            {
                day: "Kamis",
                isActive: false
            },
            {
                day: "Jumat",
                isActive: false
            },
            {
                day: "Sabtu",
                isActive: false
            },
            {
                day: "Minggu",
                isActive: false
            }
        ];
        data.forEach(item => {
            activeDays.forEach(day => {
                if (
                    day.day == item.hari ||
                    day.day == this.numToHari(item.hari)
                    //     &&
                    // item.isPublish
                ) {
                    day.isActive = true;
                }
            });
        });
        return activeDays;
    }
    static jadwalDatasMapper(data) {
        let jadwalDatas = data ? data : [];
        // jadwalDatas = jadwalDatas.sort((a, b) => {
        //     return a.hari - b.hari;
        // });
        jadwalDatas = jadwalDatas.sort((a, b) => {
            return parseInt(a.jamMulai) - parseInt(b.jamMulai);
        });
        jadwalDatas = jadwalDatas.sort((a, b) => {
            return a.hari - b.hari;
        });
        let activeJadwal = [];
        let pastJadwal = [];
        jadwalDatas.forEach(item => {
            if (!item.tanggalSelesai) {
                activeJadwal.push(item);
            } else if (
                moment(item.tanggalSelesai, "YYYY-MM-DD").isAfter(moment()) ||
                moment(item.tanggalSelesai, "YYYY-MM-DD").isSame(
                    moment().format("YYYY-MM-DD")
                )
            ) {
                activeJadwal.push(item);
            }
        });
        jadwalDatas.forEach(item => {
            if (
                item.tanggalSelesai &&
                moment(item.tanggalSelesai, "YYYY-MM-DD").isBefore(moment())
            ) {
                pastJadwal.push(item);
            }
        });
        jadwalDatas = activeJadwal.concat(pastJadwal);
        jadwalDatas = jadwalDatas.map(e => {
            return [
                {
                    hari: this.numToHari(e.hari),
                    hexColor: `#${this.changeColorBorderSlot2(e.ID)}`
                },
                e.jamMulai.slice(0, -3),
                e.jamSelesai.slice(0, -3),
                e.durationInMinute,
                e.tanggalMulai
                    ? moment(e.tanggalMulai, "YYYY-MM-DD").format("DD/MMM/YYYY")
                    : " - ",
                e.tanggalSelesai
                    ? moment(e.tanggalSelesai, "YYYY-MM-DD").format(
                          "DD/MMM/YYYY"
                      )
                    : " - ",
                {
                    text: e.isPublish ? `Published` : `Unpublished`,
                    isActive: e.isPublish
                },
                {
                    text: e.isPublishTime ? 'Shown': `Hidden`,
                    isActive: !!e.isPublishTime
                },
                {
                    text: e.isPublishSlot ? 'Shown': `Hidden`,
                    isActive: !!e.isPublishSlot
                },
            ];
        });

        return jadwalDatas;
    }
    static jadwalDatasMapperDetail(data) {
        let jadwalDatas = data ? data : [];
        jadwalDatas = jadwalDatas.sort((a, b) => {
            return parseInt(a.jamMulai) - parseInt(b.jamMulai);
        });
        jadwalDatas = jadwalDatas.sort((a, b) => {
            return a.hari - b.hari;
        });
        let activeJadwal = [];
        let pastJadwal = [];
        jadwalDatas.forEach(item => {
            if (!item.tanggalSelesai) {
                activeJadwal.push(item);
            } else if (
                moment(item.tanggalSelesai, "YYYY-MM-DD").isAfter(moment()) ||
                moment(item.tanggalSelesai, "YYYY-MM-DD").isSame(
                    moment().format("YYYY-MM-DD")
                )
            ) {
                activeJadwal.push(item);
            }
        });
        jadwalDatas.forEach(item => {
            if (
                item.tanggalSelesai &&
                moment(item.tanggalSelesai, "YYYY-MM-DD").isBefore(moment()) &&
                !moment(item.tanggalSelesai, "YYYY-MM-DD").isSame(
                    moment().format("YYYY-MM-DD")
                )
            ) {
                pastJadwal.push(item);
            }
        });
        jadwalDatas = activeJadwal.concat(pastJadwal);
        jadwalDatas = jadwalDatas.map(e => {
            return [
                {
                    hari: this.numToHari(e.hari),
                    hexColor: `#${this.changeColorBorderSlot2(e.ID)}`
                },
                e.jamMulai.slice(0, -3),
                e.jamSelesai.slice(0, -3),
                e.durationInMinute,
                e.tanggalMulai
                    ? moment(e.tanggalMulai, "YYYY-MM-DD").format("DD/MMM/YYYY")
                    : " - ",
                e.tanggalSelesai
                    ? moment(e.tanggalSelesai, "YYYY-MM-DD").format(
                          "DD/MMM/YYYY"
                      )
                    : " - ",
                {
                    text: e.isPublish ? `Published` : `Unpublished`,
                    isActive: e.isPublish
                },
                {
                    text: e.isPublishTime ? 'Shown': `Hidden`,
                    isActive: !!e.isPublishTime
                },
                {
                    text: e.isPublishSlot ? 'Shown': `Hidden`,
                    isActive: !!e.isPublishSlot
                },
                {
                    jadwalId: e.ID,
                    dokterID: e.dokterID,
                    jadwalObject: e,
                    hari: this.numToHari(e.hari)
                    // jamMulai: e.jamMulai,
                    // jamSelesai: e.jamSelesai
                }
            ];
        });
        
        return jadwalDatas;
    }
    static doctorMapper(data) {
        let doctorId = data.ID;
        let doctorName = data.nama;
        let doctorGender = data.jenisKelamin ? data.jenisKelamin : " - ";
        let doctorDob = data.tanggalLahir
            ? moment(data.tanggalLahir).format("DD/MMM/YYYY")
            : " - ";
        return {
            doctorId,
            doctorName,
            doctorGender,
            doctorDob
        };
    }
    static doctorCardDataObject(data) {
        let klinikData = this.klinikMapper(data.dokterKliniks);
        let spesialisasiData = this.spesialisasiMapper(
            data.dokterSpesialisasis
        );
        let subSpesialisasiData = this.subSpesialisasiMapper(
            data.dokterSubSpesialisasis
        );
        let activeDaysData = this.activeDaysMapper(data.jadwals);
        let jadwalDatas = this.jadwalDatasMapper(data.jadwals);

        let {
            doctorId,
            doctorName,
            doctorGender,
            doctorDob
        } = this.doctorMapper(data);

        return {
            doctorId: doctorId,
            doctorName: doctorName,
            gender: doctorGender,
            tglLahir: doctorDob,
            isProfilePublished: data.isPublish,
            klinik: klinikData,
            spesialisasi: spesialisasiData,
            subSpesialisasi: subSpesialisasiData,
            activeDays: activeDaysData,
            jadwalDatas: jadwalDatas,
            avatar: data.foto,
            fotoId: data.fotoId,
            userId: data.userId,
            tenant_id: data.tenant_id
        };
    }
    static doctorCardDataObjectDetail(data) {
        let klinikData = this.klinikMapper(data.dokterKliniks);
        let spesialisasiData = this.spesialisasiMapper(
            data.dokterSpesialisasis
        );
        let subSpesialisasiData = this.subSpesialisasiMapper(
            data.dokterSubSpesialisasis
        );

        let {
            doctorId,
            doctorName,
            doctorGender,
            doctorDob
        } = this.doctorMapper(data);

        return {
            doctorId: doctorId,
            doctorName: doctorName,
            gender: doctorGender,
            tglLahir: doctorDob,
            isProfilePublished: data.isPublish,
            klinik: klinikData,
            spesialisasi: spesialisasiData,
            subSpesialisasi: subSpesialisasiData,
            tarif: data.tarif,
            profileMhg: data.profileMhg,
            profileCss: data.profileCss,
            profileOvveride: data.profileOvveride,
            avatar: data.foto,
            fotoId: data.fotoId
            // activeDays: activeDaysData,
            // jadwalDatas: jadwalDatas
        };
    }
    static dataDoctorCard(data, icons) {
        let dataDoctorCard = [];
        data.forEach(element => {
            let doctorCardChipIcon = this.doctorCardChipIconObject(
                element,
                icons
            );
            let doctorCardData = this.doctorCardDataObject(element);
            dataDoctorCard.push({
                doctorCardChipIcon: doctorCardChipIcon,
                doctorCardData: doctorCardData
            });
        });
        return dataDoctorCard;
    }
    static detailDoctor(data, icons) {
        let doctorCardChipIcon = this.doctorCardChipIconObject(data, icons);
        let doctorCardData = this.doctorCardDataObjectDetail(data);
        let jadwalDatas = this.jadwalDatasMapperDetail(data.jadwals);
        return {
            doctorCardChipIcon: doctorCardChipIcon,
            doctorCardData: doctorCardData,
            jadwalDatas: jadwalDatas
        };
    }
    static klinikDropDown(data) {
        let klinikDropDown = [];
        data.forEach(element => {
            klinikDropDown.push({
                id: element.ID,
                nama: element.namaIndo,
                type: element.type
            });
        });
        return klinikDropDown;
    }
    static spesialisasiDropDown(data) {
        let spesialisasiDropDown = [];
        // console.log(data, "spesialisasiDropDown");
        data.forEach(element => {
            spesialisasiDropDown.push({
                isPublish: true,
                isDefault: false,
                id: element.ID,
                nama: element.namaIndo,
                canSelect: true,
                subSpesialisasi: []
            });
        });
        return spesialisasiDropDown;
    }
    static subSpesialisasiDropDown(data) {
        let subSpesialisasiDropDown = [];
        data.forEach(element => {
            subSpesialisasiDropDown.push({
                isPublish: true,
                isDefault: false,
                id: element.ID,
                nama: element.namaIndo,
                canSelect: true
            });
        });
        return subSpesialisasiDropDown;
    }
    static tipeKonsultasiDropDown(data) {
        let tipeKonsultasiDropDown = [];
        data.forEach(element => {
            tipeKonsultasiDropDown.push({
                id: element.id,
                nama: element.nama
            });
        });
        return tipeKonsultasiDropDown;
    }
    static tarifKonsultasiDropDown(data) {
        let tarifKonsultasiDropDown = [];
        data.forEach(element => {
            tarifKonsultasiDropDown.push({
                id: element.ID,
                nama: element.nama,
                jumlah: element.jumlah
            });
        });
        return tarifKonsultasiDropDown;
    }
    static addJadwalDokter(data) {
        let payload = {
            dokterID: data.dokter.id,
            hari: this.daysToNum(data.hari),
            jamMulai: data.jamMulai,
            jamSelesai: data.jamSelesai,
            durationInMinute: data.durationInMinute,
            tanggalMulai: data.tanggalMulai
                ? moment(data.tanggalMulai, "YYYY-MM-DD").utc(true).format(
                      "YYYY-MM-DDTHH:mm:ssZ"
                  )
                : "",
            tanggalSelesai: data.tanggalSelesai
                ? moment(data.tanggalSelesai, "YYYY-MM-DD").utc(true).format(
                      "YYYY-MM-DDTHH:mm:ssZ"
                  )
                : "",
            personInSlot: data.personInSlot ? data.personInSlot : 1,
            isPublish: data.isPublish,
            personInSlot: data.personInSlot ? data.personInSlot : 1,
            slotIndexBlocked: data.slotIndexBlocked
                ? data.slotIndexBlocked.join(";")
                : "",
            isPublishSlot: data.isPublishSlot,
            isPublishTime: data.isPublishTime
        };
        Object.keys(payload).forEach(key => {
            const isSelect = ['hari', 'isPublish', 'isPublishTime', 'isPublishSlot'].some(_item => _item == key)
            if (!payload[key] && !isSelect) {
                delete payload[key];
            }
        });
        return payload;
    }
    static editJadwalDokter(data, dokterObject) {
        if (data) {
            let payload = {
                id: data.id,
                hari: this.daysToNum(data.hari),
                jamMulai: data.jamMulai,
                jamSelesai: data.jamSelesai,
                durationInMinute: data.durationInMinute,
                personInSlot: data.personInSlot,
                isPublish: data.isPublish,
                isPublishTime: data.isPublishTime,
                isPublishSlot: data.isPublishSlot,
                personInSlot: data.personInSlot,
                tanggalMulai: data.tanggalMulai
                    ? moment(data.tanggalMulai, "YYYY-MM-DD").utc(true).format(
                          "YYYY-MM-DDTHH:mm:ssZ"
                      )
                    : "",
                tanggalSelesai: data.tanggalSelesai
                    ? moment(data.tanggalSelesai, "YYYY-MM-DD").utc(true).format(
                          "YYYY-MM-DDTHH:mm:ssZ"
                      )
                    : "",
                slotIndexBlocked: data.slotIndexBlocked
                    ? data.slotIndexBlocked.join(";")
                    : ""
            };
            if (data.dokter) {
                payload.dokterID = data.dokter.id;
            }
            Object.keys(payload).forEach(key => {
                const isSelect = ['hari', 'isPublish', 'isPublishTime', 'isPublishSlot'].some(_item => _item == key)
                if (!payload[key] && !isSelect) {
                    delete payload[key];
                }
            });
            return payload;
        }
    }
    static initialDataEditJadwal(data) {
        if (data) {
            let payload = {
                id: data.ID,
                // dokterID: data.dokterID,
                // dokter: {
                //     id: data.dokter.ID,
                //     nama: data.dokter.nama
                // },
                hari: this.numToDays(data.hari),
                jamMulai: data.jamMulai.slice(0, -3),
                jamSelesai: data.jamSelesai.slice(0, -3),
                durationInMinute: data.durationInMinute,
                tanggalMulai: data.tanggalMulai,
                personInSlot: data.personInSlot ? data.personInSlot : 1,
                isPublish: data.isPublish,
                personInSlot: data.personInSlot ? data.personInSlot : 1,
                isPublishTime: data.isPublishTime,
                isPublishSlot: data.isPublishSlot,
                // ? moment(data.tanggalMulai, "YYYY-MM-DD").format(
                //       "YYYY-MM-DDTHH:mm:ssZ"
                //   )
                // : ""
                tanggalSelesai: data.tanggalSelesai,
                // ? moment(data.tanggalSelesai, "YYYY-MM-DD").format(
                //       "YYYY-MM-DDTHH:mm:ssZ"
                //   )
                // : ""
                slotIndexBlocked: data.slotIndexBlocked
                    ? data.slotIndexBlocked.split(";").map(Number)
                    : []
            };
            if (data.dokter) {
                payload.dokter = {
                    id: data.dokter.ID,
                    nama: data.dokter.nama
                };
            }
            return payload;
        }

        //  moment(data.tanggalSelesai, "YYYY-MM-DD").format(
        //      "YYYY-MM-DDTHH:mm:ssZ"
        //  );

        // return {
        //     id: data.ID,
        //     dokter: {
        //         id: data.dokter.ID,
        //         nama: data.dokter.nama
        //     },
        //     hari: this.numToDays(data.hari),
        //     jamMulai: data.jamMulai,
        //     jamSelesai: data.jamSelesai,
        //     durationInMinute: data.durationInMinute,
        //     tanggalMulai: data.tanggalMulai,
        //     tanggalSelesai: data.tanggalSelesai,
        //     slotIndexBlocked: data.slotIndexBlocked.split(","),
        //     isPublish: data.isPublish
        // };
    }
    static putKlinikMapper(data) {
        let klinik = [];
        data.forEach(element => {
            klinik.push({
                klinikId: element.id,
                nama: element.namaIndo
            });
        });
        return klinik;
    }
    static putDataDokter(data) {
        if (data) {
            let tanggalLahir;
            if (data.tglLahir) {
                tanggalLahir = data.tglLahir;
            }
            let subSpesialisasiData = [];
            data.spesialisasi.forEach(spe => {
                if (spe.subSpesialisasi) {
                    spe.subSpesialisasi.forEach(sub => {
                        subSpesialisasiData.push(sub);
                    });
                }
            });
            let validated = {
                ID: data.id,
                nama: data.nama,
                jenisKelamin: data.gender,
                isPublish: data.isPublishProfile,
                isBookAllow: data.tipeBooking,
                dokterKliniks: data.klinik.map(klinik => {
                    return {
                        klinikId: klinik.id,
                        isPublish: klinik.isPublish
                    };
                }),
                dokterSpesialisasis: data.spesialisasi.map(spe => {
                    return {
                        spesialisasiId: spe.id,
                        isPublish: spe.isPublish,
                        isDefault: spe.isDefault
                    };
                }),
                dokterSubSpesialisasis: subSpesialisasiData.map(sub => {
                    return {
                        subSpesialisasiId: sub.id,
                        isPublish: sub.isPublish,
                        isDefault: sub.isDefault
                    };
                }),
                tarif: data.tarifTelekonsultasi,
                textToSpeech: data.textToSpeech
            };
            if (tanggalLahir) {
                validated.tanggalLahir = moment(
                    tanggalLahir,
                    "YYYY-MM-DD"
                ).utc(true).format("YYYY-MM-DDTHH:mm:ssZ");
            }
            if (data.imageB64 || data.imageName) {
                let image = new Photo();
                const now = Date.now();
                if (data.imageB64) {
                    image.image = data.imageB64.split(",")[1];
                }
                image.title = data.imageName;
                image.description = data.imageName;
                image.height = data.imageInfo.height;
                image.width = data.imageInfo.width;
                image.imageContentType = data.imageInfo.imageContentType;
                image.taken = data.imageInfo.taken;
                image.uploaded = data.imageInfo.uploaded;
                image.id = data.imageInfo.id;
                image.checksum = data.imageInfo.checksum;
                image.alias = data.imageName + now;
                validated.foto = data.imageName + now;
                validated.fotoId = data.imageInfo.id;
                return { validated, image };
            }
            return { validated };
        }
    }
    static postSingleAbsence(singleAbsence, jadwalObject) {
        if (singleAbsence) {
            return {
                alasan: singleAbsence.description,
                status: "CANCELLED",
                tanggal:
                    moment(singleAbsence.tanggal, "YYYY-MM-DD").format(
                        "YYYY-MM-DDTHH:MM:ss"
                    ) + "Z",
                jadwal: jadwalObject
            };
        }
    }
    static postDataDoctor(data) {
        if (data) {
            let tanggalLahir;
            if (data.tglLahir) {
                tanggalLahir = data.tglLahir;
            }
            let subSpesialisasiData = [];
            data.spesialisasi.forEach(spe => {
                if (spe.subSpesialisasi) {
                    spe.subSpesialisasi.forEach(sub => {
                        subSpesialisasiData.push(sub);
                    });
                }
            });
            let validated = {
                nama: data.nama,
                jenisKelamin: data.gender,
                isPublish: data.isPublishProfile,
                isBookAllow: data.tipeBooking,
                dokterKliniks: data.klinik.map(klinik => {
                    return {
                        klinikId: klinik.id,
                        isPublish: klinik.isPublish
                    };
                }),
                dokterSpesialisasis: data.spesialisasi.map(spe => {
                    return {
                        spesialisasiId: spe.id,
                        isPublish: spe.isPublish,
                        isDefault: spe.isDefault
                    };
                }),
                dokterSubSpesialisasis: subSpesialisasiData.map(sub => {
                    return {
                        subSpesialisasiId: sub.id,
                        isPublish: sub.isPublish,
                        isDefault: sub.isDefault
                    };
                }),
                tarif: data.tarifTelekonsultasi
            };
            if (tanggalLahir) {
                validated.tanggalLahir = moment(
                    tanggalLahir,
                    "YYYY-MM-DD"
                ).utc(true).format("YYYY-MM-DDTHH:mm:ssZ");
            }
            // image = {...data.imageInfo}
            if (data.imageB64 || data.imageName) {
                let image = new Photo();
                const now = Date.now();
                if (data.imageB64) {
                    image.image = data.imageB64.split(",")[1];
                }
                image.title = data.imageName;
                image.description = data.imageName;
                image.height = data.imageInfo.height;
                image.width = data.imageInfo.width;
                image.imageContentType = data.imageInfo.imageContentType;
                image.taken = data.imageInfo.taken;
                image.uploaded = data.imageInfo.uploaded;
                // image.id = data.imageInfo.id;
                image.alias = data.imageName + now;
                validated.foto = data.imageName + now;
                return { validated, image };
            }
            return { validated };
        }
    }
    static days(day) {
        const days = {
            SUNDAY: "Minggu",
            MONDAY: "Senin",
            TUESDAY: "Selasa",
            WEDNESDAY: "Rabu",
            THURSDAY: "Kamis",
            FRIDAY: `Jumat`,
            SATURDAY: "Sabtu",
            0: "Senin",
            1: "Selasa",
            2: "Rabu",
            3: "Kamis",
            4: "Jumat",
            5: "Sabtu",
            6: "Minggu"
        };
        if (days[day]) {
            return days[day];
        }
    }
    static jadwalsMapper(data) {
        if (data) {
            return data.map(item => {
                return {
                    ...item,
                    hari: this.days(item.hari)
                };
            });
        }
    }
    static postKetidakhadiran(data) {
        if (data) {
            if (Array.isArray(data.jadwal)) {
                return data.jadwal.map(item => {
                    return {
                        // id: null,
                        // tanggal: this.dateFormat(item.tanggal),
                        // nama: item.jadwal.dokter.nama,
                        // hari: this.days(item.jadwal.hari),
                        jadwal: item.jadwalObject,
                        status: "CANCELLED",
                        alasan: data.alasan,
                        tanggal:
                            moment(item.tanggal).format("YYYY-MM-DDTHH:MM:ss") +
                            "Z"
                    };
                });
            }
        }
    }
    static getOneDokterMapper(data) {
        if (data) {
        }
    }
}
