<template>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="kt-portlet kt-portlet--mobile head-container-kt">
            <div
                style="border-bottom: unset;padding-top: 20px"
                class="kt-portlet__head kt-portlet__head--lg header-container"
            >
                <base-title
                    class="head-label"
                    v-bind:head-title="'Detail Dokter'"
                ></base-title>
                <button
                    class="refresh-button back-button"
                    @click.prevent="backToPreviousPage"
                ></button>
            </div>
            <div v-if="isLoaded" class="kt-portlet__body">
                <DoctorCard
                    class="detail-card"
                    :doctorCardData="cardData.doctorCardData"
                    :konsultasiIcons="
                        cardData.doctorCardChipIcon.konsultasiIcons
                    "
                    :bookingIcons="cardData.doctorCardChipIcon.bookingIcons"
                >
                    <div
                        style="padding-top: 10px;border-top: 0.5px solid #95B2DB;"
                        slot="extra-info"
                        class="form-group row"
                    >
                        <label class="col-lg-2 doctor-card-label">
                            Tarif Konsultasi
                        </label>
                        <div class="col-lg-6 d-flex">
                            <!-- class="col-2" -->
                            <p class="plain-text">
                                {{ cardData.doctorCardData.tarif | tarifName }}
                            </p>
                            <!-- <p v-if="subSpesialisasi.length == 0">-</p> -->
                        </div>
                    </div>
                    <EllipsisDropdown
                        v-if="
                            permissionObject.isCanDeleteDoctor ||
                                permissionObject.isCanSeeProfileDetailDoctor ||
                                permissionObject.isCanEditDoctor
                        "
                        slot="action-button"
                    >
                        <OptionDetail
                            v-if="permissionObject.isCanSeeProfileDetailDoctor"
                            @see-detail="dokterModal(true)"
                            slot="dropdown-item"
                            :textStr="'Lihat Detail Dokter'"
                        />
                        <OptionEdit
                            v-if="permissionObject.isCanEditDoctor"
                            @edit-data="dokterModal"
                            slot="dropdown-item"
                        />
                        <OptionDelete
                            v-if="permissionObject.isCanDeleteDoctor"
                            slot="dropdown-item"
                            @delete-data="deleteData(cardData.doctorCardData)"
                        />
                    </EllipsisDropdown>
                </DoctorCard>
                <div class="detail-jadwal-table">
                    <div class="section-header">
                        <h4>Jadwal Dokter</h4>
                        <div class="d-flex">
                            <button
                                v-if="
                                    cardData.jadwalDatas.length > 0 &&
                                        permissionObject.isCanCreateDoctorAbsence
                                "
                                style=""
                                class="ketidakhadiran-button"
                                @click.prevent="absenceModal"
                            ></button>
                            <button
                                v-if="
                                    permissionObject.isCanCreateDoctorSchedule
                                "
                                class="add-jadwal"
                                @click.prevent="tambahJadwal"
                            >
                                <AddIcon :strokeColor="'#ffffff'" />
                            </button>
                        </div>
                    </div>
                    <div
                        v-if="cardData.jadwalDatas.length > 0"
                        class="jadwal-table-container"
                        style="max-height: 400px;"
                    >
                        <JadwalTable
                            :tableData="cardData.jadwalDatas"
                            :tableHeader="tableHeader"
                        >
                            <BtnAction
                                class="jadwal-action-button"
                                slot="table-action"
                                slot-scope="{ row }"
                                :rowData="row"
                                :buttonMethod="btnActionMethod"
                                :permissionObject="permissionObject"
                            />
                        </JadwalTable>
                    </div>
                    <div v-else>
                        <div style="background: #FFF1DC;" class="css-warning">
                            <img
                                :src="attentionIcon"
                                alt=""
                                style="width: 24px;"
                            />
                            <p>
                                Data jadwal belum tersedia. Silahkan tambah
                                jadwal dokter
                            </p>
                        </div>
                    </div>
                </div>
                <div class="detail-mobile-doctor">
                    <div class="section-header">
                        <h4>CSS</h4>
                        <button
                            v-if="false"
                            class="btn btn-sm btn-clean btn-icon btn-icon-md custom-button"
                        >
                            Edit
                            <EditFillIcon
                                style="width: 10.6px;"
                                :strokeColor="'#ffffff'"
                            />
                        </button>
                    </div>
                    <div class="ccs-text-input">
                        <textarea
                            name=""
                            id="css-input"
                            placeholder="This is a text field"
                            v-model="noteData.css"
                        ></textarea>
                        <div v-if="false" class="css-warning">
                            <img
                                :src="warningIcon"
                                alt=""
                                style="width: 24px;"
                            />
                            <p>
                                Simpan data error. Silakan simpan CSS terlebih
                                dahulu sebelum melanjutkan
                            </p>
                        </div>
                        <div
                            v-if="false"
                            class="css-buttons"
                            style="margin-top: 25px;margin-bottom: 42px;"
                        >
                            <button class="batal-button">Batal</button>
                            <button class="simpan-button">Simpan</button>
                        </div>
                    </div>

                    <div class="data-html">
                        <div class="preview-tab">
                            <label
                                :style="
                                    !isDataPreview
                                        ? ''
                                        : 'background:#f3f8ff;color: #95B2DB;'
                                "
                                for="data-edit"
                                >Edit</label
                            >

                            <label
                                :style="
                                    isDataPreview
                                        ? ''
                                        : 'background:#f3f8ff;color: #95B2DB;'
                                "
                                for="data-preview"
                                >Preview</label
                            >
                            <input
                                v-model="isDataPreview"
                                :value="true"
                                type="radio"
                                name="preview"
                                id="data-preview"
                            />
                            <input
                                v-model="isDataPreview"
                                :value="false"
                                type="radio"
                                name="edit"
                                id="data-edit"
                            />
                        </div>
                        <!-- <div>input area {{ isDataPreview }}</div> -->
                        <div class="note-container">
                            <div v-if="!isDataPreview">
                                <p>Mandaya Hospital Group</p>
                                <div
                                    name="mandaya_group"
                                    id="summernote_mandaya_group"
                                    class="summer-note"
                                ></div>
                            </div>
                            <div v-if="!isDataPreview">
                                <p>Care Dokter</p>
                                <div
                                    name="care_dokter"
                                    id="summernote_care_dokter"
                                    class="summer-note"
                                ></div>
                            </div>
                            <div v-if="isDataPreview">
                                <section>
                                    <!-- <div
                                        v-if="dataPreviewHtml"
                                        style="background:white;border-radius:6px;padding:20px;overflow: auto;height: 317px;"
                                        v-html="dataPreviewHtml"
                                    ></div> -->
                                    <iframe  style="background:white;border-radius:6px;padding:20px;overflow: auto;height: 317px;width:100%" v-if="dataPreviewHtml" :srcdoc="dataPreviewHtml" frameborder="0"></iframe>
                                    <div v-else>
                                        <div class="css-warning">
                                            <img
                                                :src="warningIcon"
                                                alt=""
                                                style="width: 24px;"
                                            />
                                            <p>
                                                Silakan Ketik CSS & Data Pada
                                                Tab Edit bagian Care Dokter
                                                terlebih dahulu untuk melihat
                                                Preview
                                            </p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                    <div class="css-buttons" style="margin-top: 25px;">
                        <button
                            v-if="permissionObject.isCanSaveDataProfileMhg"
                            @click.prevent="simpanProfileMhg"
                            class="btn btn-primary custom-modal-button"
                        >
                            Simpan Data
                        </button>
                    </div>
                </div>
            </div>
            <div v-else class="m-auto p-5 d-flex flex-row h-100">
                <div
                    class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"
                ></div>
                <h5 class="my-auto pl-5">Loading ...</h5>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTitle from "../_base/BaseTitle";
import DoctorCard from "./components/DoctorCard.vue";
import EllipsisDropdown from "./components/EllipsisDropdown.vue";
import JadwalTable from "./components/JadwalTable.vue";

import OptionDetail from "./components/OptionSeeDetailButton.vue";
import OptionEdit from "./components/OptionEditDokterButton.vue";
import OptionDelete from "./components/OptionDeleteButton.vue";

import AddIcon from "../_icons/AddIcon.vue";
import EditFillIcon from "../_icons/EditFillIcon.vue";
import WarningIcon from "../_icons/WarningIcon.svg";
import AttentionIcon from "../_icons/AttentionIcon.svg";

import BtnAction from "./components/BtnAction.vue";

export default {
    name: "DoctorDetailScreen",
    components: {
        BaseTitle,
        DoctorCard,
        EllipsisDropdown,
        JadwalTable,

        OptionDetail,
        OptionEdit,
        OptionDelete,

        AddIcon,
        EditFillIcon,
        BtnAction
    },
    watch: {
        isLoaded(val, oldVal) {
            if (!oldVal && val) {
                if (this.cardData) {
                    if (this.cardData.doctorCardData) {
                        this.noteData.mandaya_group = this.cardData.doctorCardData.profileMhg;
                        this.noteData.care_dokter = this.cardData.doctorCardData.profileOvveride;
                        this.noteData.css = this.cardData.doctorCardData.profileCss;
                        this.initPreview();
                    }
                }
                this.initNote();
            }
        },
        isDataPreview(val, oldVal) {
            if (val) {
                $("#summernote_mandaya_group").summernote("destroy");
                $("#summernote_care_dokter").summernote("destroy");
                this.initPreview();
            } else {
                this.initNote();
            }
        }
    },
    props: {
        cardData: {
            type: Object,
            default: () => {}
        },
        isLoaded: {
            type: Boolean,
            default: false
        },
        methodObject: {
            type: Object
        },
        btnActionMethod: {
            type: Object
        },
        permissionObject: {
            type: Object
        }
    },
    filters: {
        tarifName(tarif) {
            let tarifStr = "-";
            if (tarif) {
                let jumlah = 0;
                if (tarif.jumlah) {
                    jumlah = tarif.jumlah;
                }
                jumlah = `Rp. ${jumlah.toLocaleString("id-ID")}`;
                tarifStr = `${tarif.nama} - ${jumlah}`;
            }
            return tarifStr;
        }
    },
    data() {
        return {
            tableHeader: [
                `Hari`,
                `Jam Mulai`,
                `Jam Selesai`,
                `Durasi`,
                `Tanggal Mulai`,
                `Tanggal Selesai`,
                `Jadwal`,
                `Jam`,
                `Slot`,
                `Action`
            ],
            isDataPreview: true,
            warningIcon: WarningIcon,
            attentionIcon: AttentionIcon,
            noteData: {
                mandaya_group: null,
                care_dokter: null,
                css: null
            },
            isSummerNoteLoading: false,
            dataPreviewHtml: null
        };
    },
    methods: {
        summerNote(idEl, keyNameModel, contentCode) {
            let config = {
                height: 150,
                callbacks: {
                    onChange: contents => {
                        this.noteData[keyNameModel] = contents;
                    },
                    onBlurCodeview: (contents, $editable) => {
                        this.noteData[keyNameModel] = contents;
                    }
                }
            };
            $(idEl).summernote(config);
        },
        backToPreviousPage() {
            this.$router.push({
                name: "dokter-new"
            });
        },
        tambahJadwal() {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.openTambahJadwalModalMethod ==
                    "function"
                ) {
                    this.methodObject.openTambahJadwalModalMethod();
                }
            }
        },
        absenceModal() {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.openKetidakhadiranModalMethod ==
                    "function"
                ) {
                    this.methodObject.openKetidakhadiranModalMethod();
                }
            }
        },
        simpanProfileMhg() {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.simpanProfileMhgMethod ==
                    "function"
                ) {
                    this.methodObject.simpanProfileMhgMethod(this.noteData);
                }
            }
        },
        dokterModal(isViewOnly) {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.openDokterModalMethod == "function"
                ) {
                    this.methodObject.openDokterModalMethod(isViewOnly);
                }
            }
        },
        deleteData({ doctorId, doctorName }) {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.deleteConfirmationMethod ==
                    "function"
                ) {
                    this.methodObject.deleteConfirmationMethod(
                        {
                            id: doctorId,
                            nama: doctorName
                        },
                        "Data dokter akan terhapus secara permanen",
                        "Apakah Anda yakin ingin menghapus data dokter berikut?"
                    );
                }
            }
        },
        initNote(isEdit, mhg, careDoc) {
            setTimeout(() => {
                this.summerNote("#summernote_mandaya_group", "mandaya_group");
                $("#summernote_mandaya_group").summernote(
                    "code",
                    this.noteData.mandaya_group
                );
            }, 500);
            setTimeout(() => {
                this.summerNote("#summernote_care_dokter", "care_dokter");
                $("#summernote_care_dokter").summernote(
                    "code",
                    this.noteData.care_dokter
                );
                // $("#summernote_care_dokter").summernote("code", this.dummy);
            }, 500);
        },
        initPreview() {
            if (this.noteData.care_dokter) {
                let html = `<div class="html-preview" style="color:unset;">`;
                html += this.noteData.care_dokter;
                html += `</div>`;
                let css = "";
                if (this.noteData.css) {
                    css = this.noteData.css.trim().split("}");
                    css = css
                        .filter(item => item)
                        .map(item => {
                            if (item) {
                                let classStr = ``;
                                item = classStr += item + "}";

                                return item;
                            }
                        });
                    css = css.join("");
                    css = `<style>` + css + `</style>`;
                }
                this.dataPreviewHtml =
                    // `<html> <head>` +
                    css +
                    // `</head> <body>` +
                    html;
                // `</body>` +
            }
        }
    }
};
</script>

<style></style>
